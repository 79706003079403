<template lang="pug">
.brand-wrapper.brand-mobile-redirect-wrapper.container
  .d-flex.align-items-center
    img.brand-mobile-redirect-logo(:src="require('@/assets/om-logo.svg')")
    a.brand-link.ml-auto(href="javascript:void(0)" @click="logout") {{ $t('menu.signOut') }}
  template(v-if="!step")
    img.brand-mobile-redirect-editor-mockup.mt-5.mx-auto(
      :src="require('@/assets/admin/svg/editor-mockup.svg')"
    )
    .brand-mobile-redirect-title.font-weight-bold.text-center.mt-4.mb-3(
      v-html="$t('mobileRedirect.title')"
    )
    .brand-mobile-redirect-lead.mt-4.mb-4.text-center.font-size-1.py-1(
      v-html="$t('mobileRedirect.lead')"
    )
    om-button.brand-mobile-onboarding-btn(primary large block @click="send") {{ $t('mobileRedirect.send') }}
  template(v-if="step === 'success'")
    img.brand-mobile-redirect-happy-monk(:src="require('@/assets/admin/svg/monk-and-bela-2.svg')")
    .brand-mobile-redirect-title.font-weight-bold.text-center {{ $t('mobileRedirect.success.title') }}
    .brand-mobile-redirect-lead.my-4.font-size-1.py-1 {{ $t('mobileRedirect.success.lead') }}
    om-button.brand-mobile-onboarding-btn(primary large block @click="redirectUri") {{ $t('mobileRedirect.success.cta') }}
</template>
<script>
  import SEND_MAGIC_LINK_EMAIL from '@/graphql/SendMagicLinkEmail.gql';
  import { mapActions, mapGetters } from 'vuex';
  import { redirectToHomeScreen } from '@/router/guards';

  export default {
    data() {
      return {
        email: '',
        step: null,
      };
    },

    computed: {
      ...mapGetters(['isMobile']),
      userEmail() {
        return this.$store.state.account.login.email;
      },
      template() {
        return this.$route.query.template;
      },
    },

    watch: {
      userEmail(v) {
        this.email = v;
      },
    },

    created() {
      if (!this.isMobile) {
        const route = redirectToHomeScreen({
          to: { fullPath: '/' },
          store: this.$store,
          params: null,
        });
        return this.$router.push(route);
      }
      this.step = this.$route.params.step;
      this.email = this.userEmail;
    },

    methods: {
      ...mapActions(['logout']),
      redirectUri() {
        let redirectUri = 'https://www.optimonk.hu/esettanulmanyok/';
        if (this.$i18n.locale !== 'hu') {
          redirectUri = 'https://www.optimonk.com/case-studies';
        }
        window.location.assign(redirectUri);
      },
      async send() {
        await this.$apollo.mutate({
          mutation: SEND_MAGIC_LINK_EMAIL,
          variables: {
            email: this.email,
            template: this.template,
          },
        });
        this.$router.push({ name: 'mobile_redirect', params: { step: 'success' } });
      },
    },
  };
</script>

<style lang="sass">
  .brand-mobile-redirect
    font-size: 16px !important
    body
      background: white

    &-wrapper
      padding: 1.25rem 2rem !important
      position: relative

    &-logo
      height: 2rem
    &-monk-and-bela
      margin-left: auto
      margin-right: auto
      margin-top: 4.375rem
      margin-bottom: 2.5rem
    &-happy-monk
      margin: 4.75rem auto 2.8125rem
      width: 22.75rem

    &-title
      font-size: 1.75rem
      line-height: 1.45
      margin-bottom: 1rem

    &-lead
      font-size: 0.9375rem
      color: #878C90
      line-height: 1.65
</style>
